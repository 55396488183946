<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <c-btn
          text
          class="mb-2"
          :label="$t('main.back_to_list')"
          :icon-props="{
            icon: 'mdi-chevron-left'
          }"
          @click="$router.push({ name: routeNames.RTB_ENDPOINTS })"
        />
        <v-card>
          <v-progress-linear :active="isLoading" indeterminate color="primary" />
          <v-card-title class="secondary">
            {{
              isEditing
                ? $t('rtb.create_endpoint.editing_str')
                : $t('rtb.create_endpoint.create_str')
            }}
          </v-card-title>
          <v-card-text>
            <c-text-field
              v-model="rtbEndpoint.endpoint"
              class="mt-4"
              :error-messages="errors.endpoint"
              :label="$t('rtb.create_endpoint.endpoint_url')"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <c-btn text :label="$t('main.cancel')" @click="$router.push({ name: routeNames.RTB_ENDPOINTS })" />
            <c-btn
              color="primary"
              :disabled="isLoading"
              :label="
                isEditing
                  ? $t('rtb.create_endpoint.save')
                  : $t('rtb.create_endpoint.create_endpoint')
              "
              @click="submit()"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import rtbEndpointsRepository from '@/services/repositories/rtb-endpoints-repository.js'
  import ValidationError from '@/services/classes/validation-error.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import { showErrorNotification } from '@/plugins/global-methods.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'RtbCreateEndpoint',
    components: {
      CTextField,
      CBtn
    },
    props: {
      id: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        routeNames,
        isLoading: false,
        errors: {},
        rtbEndpoint: {}
      }
    },
    computed: {
      isEditing() {
        return Boolean(this.id)
      }
    },
    created() {
      if (this.isEditing) {
        this.fetchEditingEndpoint()
      }
    },
    methods: {
      async fetchEditingEndpoint() {
        this.isLoading = true
        try {
          this.rtbEndpoint = await rtbEndpointsRepository.show(this.id)
        } catch (error) {
          showErrorNotification(error.message)
        }
        this.isLoading = false
      },
      submit() {
        this.isLoading = true
        const request = this.isEditing
          ? rtbEndpointsRepository.update(this.id, this.rtbEndpoint)
          : rtbEndpointsRepository.store(this.rtbEndpoint)
        request
          .then(() => {
            let title
            if (this.isEditing) {
              title = this.$t('rtb.create_endpoint.rtb_name_saved', { name: this.rtbEndpoint.name })
              gtmPush({ event: GTM_EVENTS.CREATE_RTB_ENDPOINT })
            } else {
              title = this.$t('rtb.create_endpoint.rtb_name_created', { name: this.rtbEndpoint.name })
            }
            this.$showSuccessNotification(title)
            this.$router.push({ name: routeNames.RTB_ENDPOINTS })
          })
          .catch((error) => {
            if (error instanceof ValidationError) {
              this.errors = error.messages
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
</script>
